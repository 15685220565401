import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DownloadAppModalComponent, ModalService } from '@uc/shared/ui';
import { DownloadAppGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-download-app-btn',
	templateUrl: './download-app-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class DownloadAppBtnComponent implements OnInit {
	@Input() buttonText = 'Download The App';
	@Input() product: 'default' | 'clearing' = 'default';
	@Input() link = 'https://onelink.to/unicompare';
	@Input() customStyles = 'blue-button';

	constructor(
		private _modalSrv: ModalService,
		private _downloadAppGTMSrv: DownloadAppGTMService,
	) {}

	ngOnInit(): void {
		this._modalSrv.setData({ product: this.product });
	}

	openModal() {
		this._modalSrv.setContent(DownloadAppModalComponent);
		this._modalSrv.show();
	}

	onDownloadAppClick() {
		this._downloadAppGTMSrv.downloadAppClick();
	}
}
