import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingImageSvgComponent } from '@uc/shared/svg';
@Component({
	selector: 'uc-universities-skeleton',
	templateUrl: './universities-skeleton.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, LoadingImageSvgComponent],
})
export class UniversitiesSkeletonComponent {
	@Input() filter = true;
	@Input() card = true;
}
