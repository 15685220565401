import { isPlatformBrowser, NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	input,
	output,
	PLATFORM_ID,
	viewChild,
} from '@angular/core';
import { ArrowLeftSvgComponent, ArrowRightSvgComponent } from '@uc/shared/svg';
import { DegreeLevelOption } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-degree-level-selector',
	templateUrl: './degree-level-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, ArrowLeftSvgComponent, ArrowRightSvgComponent],
})
export class DegreeLevelSelectorComponent implements AfterViewInit {
	degreeLevels = input.required<DegreeLevelOption[]>();
	selectedDegreeLevel = input.required<DegreeLevelOption>();

	selectDegreeLevel = output<DegreeLevelOption>();

	content = viewChild<ElementRef>('content');
	container = viewChild<ElementRef>('container');

	scrolledLeft = 0;
	scrollWidth = 250;

	constructor(@Inject(PLATFORM_ID) private _platform: object) {}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this._platform)) {
			const [element] =
				this.content()?.nativeElement.querySelectorAll(
					'[data-selected="true"]',
				) || [];

			element?.scrollIntoView({
				behavior: 'instant',
				block: 'nearest',
				inline: 'center',
			});
		}
	}

	// Sets the product chosen by the user on mobile view.
	onSelectDegreeLevel(degreeLevel: DegreeLevelOption) {
		this.selectDegreeLevel.emit(degreeLevel);
	}

	onPrev() {
		const element = this.content()?.nativeElement;
		if (!element) return;

		element.scrollLeft -= this.scrollWidth;
		const scrollLeft = element.scrollLeft;
		this.scrolledLeft = scrollLeft;
	}

	onNext() {
		const element = this.content()?.nativeElement;
		if (!element) return;

		element.scrollLeft += this.scrollWidth;
		const scrollLeft = element.scrollLeft;
		this.scrolledLeft = scrollLeft;
	}
}
