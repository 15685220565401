import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import {
	ClientFeatureOptions,
	FeaturedCalls,
	FeaturedRegisteredInterest,
	FeaturedClearingVisitWebsite,
	ClientFeatures,
} from '@uc/web/shared/data-models';
import { ClientValidatorService } from '@uc/web/core';
import { CallUniBtnComponent } from '../buttons/call-uni-btn/call-uni-btn.component';
import { RegisterInterestBtnComponent } from '../buttons/register-interest-btn/register-interest-btn.component';
import { ClearingVisitWebsiteBtnComponent } from '../buttons/clearing-visit-website-btn/clearing-visit-website-btn.component';

@Component({
	selector: 'uc-cta-clearing-university-btns',
	templateUrl: './cta-clearing-university-btns.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RegisterInterestBtnComponent,
		CallUniBtnComponent,
		ClearingVisitWebsiteBtnComponent,
	],
})
export class CtaClearingUniversityBtnsComponent implements OnInit {
	uniName = input.required<string>();
	cleSearchOrderPosition = input.required<number>();
	uniId = input.required<number>();
	uniSlug = input.required<string>();
	externalUrl = input<string>('');
	phoneNumber = input<string>('');
	paidFeatures = input.required<ClientFeatureOptions[]>();
	privacyPolicyUrl = input.required<string | null>();
	featuredCalls!: FeaturedCalls;
	featuredRegisteredInterest!: FeaturedRegisteredInterest;
	featuredClearingVisitWebsite!: FeaturedClearingVisitWebsite;
	showTemplate = true;

	constructor(private cvSrv: ClientValidatorService) {}

	ngOnInit(): void {
		this.featuredCalls = this.cvSrv.checkFeature(
			this.paidFeatures(),
			ClientFeatures.Calls,
		);

		this.featuredRegisteredInterest = this.cvSrv.checkFeature(
			this.paidFeatures(),
			ClientFeatures.RegisterInterest,
		);

		this.featuredClearingVisitWebsite = this.cvSrv.checkFeature(
			this.paidFeatures(),
			ClientFeatures.ClearingVisitWebsite,
		);
		this.showTemplate = this.hasFeatureAndValue();
	}

	hasFeatureAndValue() {
		return ((this.featuredCalls && this.phoneNumber) ||
			(this.featuredClearingVisitWebsite && this.externalUrl) ||
			this.featuredRegisteredInterest) as boolean;
	}
}
