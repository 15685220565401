import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { Params, RouterModule } from '@angular/router';

type Slug = string;

@Component({
	selector: 'uc-secondary-blue-btn',
	templateUrl: './secondary-blue-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class SecondaryBlueBtnComponent {
	@Input() link = '';
	@Input() button = false;
	@Input() buttonText = '';
	@Input() customStyles = '';
	@Input() id!: number | Slug;
	@Input() queryParams: Params = {};
	@Output() buttonClicked = new EventEmitter();
}
