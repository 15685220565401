import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { PromoSlot, PromoSlotComponentName } from '@uc/web/shared/data-models';
import { PromoSlotService } from '../../services/promo-slot.service';
import { InAppBrowserMessageHandlerService } from '@uc/web/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'uc-promo-slot-card',
	templateUrl: './promo-slot-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class PromoSlotCardComponent {
	card = input.required<PromoSlot>();
	cardIndex = input.required<number>();
	section = input<1 | 2>(1);
	customHeight = input<string>('lg:h-44');
	isInAppBrowser = input<boolean>(false);

	constructor(
		private _promoSlotSrv: PromoSlotService,
		private _inAppBrowserMessageHandlerSrv: InAppBrowserMessageHandlerService,
	) {}

	onClick() {
		this._promoSlotSrv.handlePromoSlotClick(
			this.card().link,
			PromoSlotComponentName.ROW,
			this.cardIndex(),
			this.section(),
			this.card().university?.id,
			this.card().university?.name,
			this.card().is_tracked,
			this.isInAppBrowser(),
		);

		if (this.isInAppBrowser()) {
			this._inAppBrowserMessageHandlerSrv.ios.onPromoSlot(this.card().link);
		}
	}
}
