import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-leads-panel-right',
	imports: [NgOptimizedImage, CdnPathPipe],
	templateUrl: './leads-panel-right.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadsPanelRightComponent {
	heading = input.required<string>();
	description = input.required<string>();
	imageName = input.required<string>();
}
