import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { CloseModalSvgComponent } from '@uc/shared/svg';
import {
	CourseSearchPillUrlKey,
	UniversityMultiOptionQueryParam,
} from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-filter-pill',
	imports: [CloseModalSvgComponent],
	templateUrl: './filter-pill.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPillComponent {
	@Input({ required: true }) pillLabel!: string;
	@Input({ required: true }) urlKey!:
		| CourseSearchPillUrlKey
		| UniversityMultiOptionQueryParam;
	@Input() urlValue = '';
	@Input() removeActionDisabled: boolean | undefined = false;

	@Output() removeFilter = new EventEmitter<{
		urlKey: CourseSearchPillUrlKey | UniversityMultiOptionQueryParam;
		urlValue: string;
	}>();
}
