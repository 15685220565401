import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArrowRightSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { InAppBrowserMessageHandlerService } from '@uc/web/core';

@Component({
	selector: 'uc-in-app-browser-search-bar',
	templateUrl: './in-app-browser-search-bar.component.html',
	imports: [SearchSvgComponent, ArrowRightSvgComponent, FormsModule],
})
export class InAppBrowserSearchBarComponent {
	@Input() placeholder = 'Search Courses';
	searchTerm = '';

	constructor(
		private _inAppBrowserMessageHandlerSrv: InAppBrowserMessageHandlerService,
	) {}

	onSearch() {
		this._inAppBrowserMessageHandlerSrv.ios.onCourseSearch(this.searchTerm);
	}
}
