import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReviewGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-write-review-btn',
	templateUrl: './write-review-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class WriteReviewBtnComponent {
	@Input({ required: true }) universityId!: number | undefined;
	@Input({ required: true }) universityName!: string;
	@Input({ required: true }) universityUrl!: string;
	@Input() buttonText = 'Write Review';
	@Input() customStyles = 'text-sm';

	constructor(private _reviewGTMSrv: ReviewGTMService) {}

	onReviewButton() {
		this.universityId &&
			this._reviewGTMSrv.reviewButton(this.universityId, this.universityName);
	}
}
