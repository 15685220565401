import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { InAppBrowserSearchBarComponent } from '../in-app-browser-search-bar/in-app-browser-search-bar.component';
import { capitaliseFirstLetter } from '@uc/utilities';
import { ArrowRightSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CourseGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-course-search-banner',
	templateUrl: './course-search-banner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		InAppBrowserSearchBarComponent,
		SearchSvgComponent,
		ArrowRightSvgComponent,
		FormsModule,
	],
})
export class CourseSearchBannerComponent {
	product = input<Product>('undergraduate');
	customHeading = input<string>('');
	description = input<string>('');
	uniName = input<string>('');
	uniSlug = input<string>('');
	openSearchInNewTab = input<boolean>(false);
	isInAppBrowser = input<boolean>(false);

	searchTerm = '';

	heading = computed(() => this._generateHeading());

	constructor(
		private _router: Router,
		private _courseGTMSrv: CourseGTMService,
	) {}

	onSearch() {
		const link = this._getCourseSearchLink();
		this._courseGTMSrv.setSearchLocation('Search Courses Banner');
		this._router.navigateByUrl(link);
	}

	private _generateHeading(): string {
		return (
			this.customHeading() ||
			`Search ${capitaliseFirstLetter(this.product())} Courses at ${this.uniName()}`
		);
	}

	private _getCourseSearchLink(): string {
		const baseLink = '/courses/degrees';

		if (!this.searchTerm) return baseLink;

		if (this.product() === ProductEnum.Postgraduate) {
			return `${baseLink}/${this.product()}/${this.searchTerm}`;
		} else if (this.product() === ProductEnum.Clearing) {
			return `/courses/clearing/${this.searchTerm}`;
		}

		return `${baseLink}/${this.searchTerm}`;
	}
}
