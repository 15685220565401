import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { EngageCardCtaImgComponent } from '../engage-card-cta/components/engage-card-cta-img/engage-card-cta-img.component';
import { RouterModule } from '@angular/router';
import { DownloadAppModalComponent, ModalService } from '@uc/shared/ui';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';
import { DownloadAppBtnComponent } from '../../buttons/download-app-btn/download-app-btn.component';
import { DownloadAppGTMService } from '@uc/web/core';
import { clearingCopy, copy } from './download-app-cta.properties';

@Component({
	selector: 'uc-download-app-cta',
	imports: [
		EngageCardCtaImgComponent,
		RouterModule,
		NgOptimizedImage,
		CdnPathPipe,
		DownloadAppBtnComponent,
	],
	templateUrl: './download-app-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadAppCtaComponent implements OnInit {
	product = input.required<'clearing' | 'default'>();
	data = copy;

	constructor(
		private _modalSrv: ModalService,
		private _downloadAppGTMSrv: DownloadAppGTMService,
	) {}

	ngOnInit(): void {
		this._modalSrv.setData({ product: this.product });

		if (this.product() === 'clearing') this.data = clearingCopy;
	}

	showModal() {
		this._modalSrv.setContent(DownloadAppModalComponent);
		this._modalSrv.show();
	}

	onDownLoadAppClick() {
		this._downloadAppGTMSrv.downloadAppClick();
	}
}
