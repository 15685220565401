import {
	ChangeDetectionStrategy,
	Component,
	computed,
	input,
	OnInit,
	output,
	signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownDirective } from '@uc/utilities';
import { ArrowRightSvgComponent, ArrowDownSvgComponent } from '@uc/shared/svg';
import { CourseSearchLocationGA, DegreeLevelOption } from '@uc/web/shared/data-models';
import { CourseFinderComponent } from '../course-finder/course-finder.component';
import { DegreeLevelService } from '@uc/web/shared/data-access';
import { CourseGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-course-search-bar',
	templateUrl: './course-search-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DropdownDirective,
		FormsModule,
		ArrowRightSvgComponent,
		ArrowDownSvgComponent,
		CourseFinderComponent,
	],
})
export class CourseSearchBarComponent implements OnInit {
	options = input.required<DegreeLevelOption[]>();
	searchLocation = input.required<CourseSearchLocationGA>();
	subject = input('', {
		transform: (value: string | undefined) => value?.replace(/-/g, ' '),
	});
	autoSuggestEnabled = input(false);
	disableError = input(false);

	enter = output<{ searchTerm: string; degree?: string }>();

	selectedOption = computed(() => this._degreeLevelSrv.selectedDegreeLevel());
	error = signal<string>('');

	searchTerm = '';

	constructor(
		private _degreeLevelSrv: DegreeLevelService,
		private _courseGTMService: CourseGTMService,
	) {}

	ngOnInit(): void {
		this.searchTerm = this.subject() ?? '';
	}

	onDegreeLevelChange(option: DegreeLevelOption): void {
		this._degreeLevelSrv.selectedDegreeLevel.set(option);
	}

	onSearch(autosuggest: { searchTerm: string }): void {
		this._courseGTMService.setSearchLocation(this.searchLocation());

		if (!autosuggest.searchTerm && !this.disableError()) {
			this.error.set('Please enter a subject');
			this._courseGTMService.noSearchTermError.set(true);
			return;
		}

		this._courseGTMService.noSearchTermError.set(false);
		this.enter.emit({
			searchTerm: autosuggest?.searchTerm,
			degree: this.selectedOption()?.link,
		});
	}

	onSearchChange(searchTerm: string) {
		this.error.set('');
		this.searchTerm = searchTerm;
	}
}
