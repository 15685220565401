import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BookmarkComponent } from '../bookmark/bookmark.component';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-university-header',
	templateUrl: './university-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, NgOptimizedImage, BookmarkComponent],
})
export class UniversityHeaderComponent {
	logo = input.required<string>();
	uniName = input.required<string>();
	saved = input<number | undefined | boolean>();
	id = input<number | undefined>(); // University ID | Course ID
	/*
	 * delta takes the values 1 | 2
	 * 1 = save university
	 * 2 = save course
	 */
	delta = input.required<1 | 2>();
	courseSlug = input<string>('');
	ugSearchOrderPosition = input<number | null>(null);
	cleSearchOrderPosition = input<number | null>(null);
	academicYear = input<number>();
}
