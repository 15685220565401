import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SafePipe } from '@uc/utilities';

@Component({
	selector: 'uc-our-location',
	templateUrl: './our-location.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SafePipe, CommonModule],
})
export class OurLocationComponent implements OnInit {
	@Input({ required: true }) universityName!: string | undefined;
	@Input({ required: true }) googleMap!: string;
	@Input({ required: true }) address1!: string;
	@Input({ required: true }) postcode!: string;
	@Input({ required: true }) country!: string;
	@Input() address2!: string;
	@Input() headerPrefix = '';
	@Input() headerSuffix = ' Location';

	ngOnInit(): void {
		const addressArr = this.address1?.split('\n');
		if (addressArr.length > 1) {
			this.address1 = addressArr[0];
			this.address2 = addressArr[addressArr.length - 1];
		}
	}
}
