import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShareArticleService } from './share-article.service';
import { ToastrService } from 'ngx-toastr';
import { ArticleGTMService } from '@uc/web/core';
import {
	EmailRoundSvgComponent,
	FacebookSvgComponent,
	LinkSvgComponent,
	LinkedinRoundSvgComponent,
	PinterestSvgComponent,
	TwitterSvgComponent,
} from '@uc/shared/svg';

export interface ShareLinksInterface {
	twitter: string | null;
	facebook: string | null;
	linkedin: string | null;
	pinterest: string | null;
	email: string | null;
}

@Component({
	selector: 'uc-share-article',
	imports: [
		TwitterSvgComponent,
		FacebookSvgComponent,
		LinkedinRoundSvgComponent,
		PinterestSvgComponent,
		EmailRoundSvgComponent,
		LinkSvgComponent,
	],
	templateUrl: './share-article.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareArticleComponent implements OnInit {
	@Input() title!: string;
	@Input() seoDesc!: string;
	@Input() imgName!: string;
	@Input() url!: string;

	shareLink: ShareLinksInterface = {
		twitter: '',
		facebook: '',
		linkedin: '',
		pinterest: '',
		email: '',
	};

	constructor(
		private share: ShareArticleService,
		private clipboard: Clipboard,
		private toastr: ToastrService,
		private articleGTMSrv: ArticleGTMService,
	) {}

	ngOnInit(): void {
		for (const [key] of Object.entries(this.shareLink)) {
			(this.shareLink as any)[key] = this.share.createShareLink(
				key,
				this.title,
				this.url,
				this.imgName,
			);
		}
	}

	copyLink() {
		const copySuccess = this.clipboard.copy(this.url);
		if (copySuccess === true) {
			this.toastr.show('', 'Link copied to clipboard!');
		} else {
			this.toastr.show('', 'Oops! Something went wrong');
		}
	}

	/*
	  GTM event - User Clicks share button on any article
	*/
	onShareArticle(articleName: string, shareType: string) {
		this.articleGTMSrv.shareArticle(articleName, shareType);
	}
}
