<div class="relative w-full mt-8 text-lg md:h-14 md:rounded-lg">
	<uc-course-finder
		[subject]="subject()"
		[showDegreeLevelOptions]="false"
		[disableError]="disableError()"
		[(error)]="error"
		(autosuggest)="onSearch($event)"
		(searchChange)="onSearchChange($event)"
		context="courseSearchBar"
		errorPosition="bottom"
		class="block md:w-[calc(100%-23rem)] h-full"
		[searchLocation]="searchLocation()"
	/>

	<div
		ucDropdown
		class="right-0 w-full mt-4 md:mt-0 md:absolute -top-16 md:top-0 md:right-36 md:w-56"
	>
		<button
			class="inline-flex items-center justify-between w-full px-4 py-2.5 text-sm font-medium text-gray-700 capitalize bg-white rounded-lg md:py-4 md:border-gray-200 md:rounded-none md:border-l hover:bg-gray-50"
			aria-haspopup="true"
		>
			<span class="truncate">{{ selectedOption().label }}</span>
			<uc-arrow-down-svg customStyles="-mr-1" />
		</button>
		<ul
			class="absolute right-0 grid w-full sm:!w-fit py-1 bg-white rounded-md shadow-lg home-dropdown-content mt-1 sm:mt-3 min-w-[14rem] md:rounded-t-none md:level-dropdown whitespace-nowrap"
			role="menu"
			aria-orientation="vertical"
			tabindex="-1"
		>
			@for (option of options(); track $index) {
				<li
					(click)="onDegreeLevelChange(option)"
					class="px-4 py-2 text-left text-gray-700 capitalize cursor-pointer text-md hover:bg-gray-50"
					role="menuitem"
					tabindex="-1"
				>
					{{ option?.label }}
				</li>
			}
		</ul>
	</div>
	<button
		type="submit"
		class="bottom-0 right-0 flex items-center justify-center w-full py-3 mt-4 text-sm font-bold bg-white border-l-2 rounded-lg sm:text-xl sm:font-semibold h-11 border-l-uc-blue-900 text-uc-blue-900 md:mt-0 md:absolute top-16 md:top-0 md:h-auto md:rounded-r-md md:rounded-none hover:bg-gray-100 md:w-36"
		id="buttonHomeSearch"
		(click)="onSearch({ searchTerm })"
	>
		Search
		<uc-arrow-right-svg customStyles="w-4 h-4 sm:w-5 sm:h-5 ml-2.5" />
	</button>
</div>
