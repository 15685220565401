import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
	selector: 'uc-main-header',
	imports: [NgClass],
	templateUrl: './main-header.component.html',
})
export class MainHeaderComponent {
	heading = input.required<string>();
	description = input.required<string>();
	backgroundStyle = input.required<string>();
	textContainer = input<string>('max-w-prose');
	textColor = input<string>('text-white');
	descriptionColor = input<string>('text-white');
	fontSize = input<string>('text-3xl');
	customPadding = input<string>('lg:py-28');
}
