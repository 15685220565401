import { Component, inject, OnDestroy } from '@angular/core';
import { ExternalLinkSvgComponent } from '@uc/shared/svg';
import { CloseModalComponent, ModalService } from '@uc/shared/ui';
import { VisitWebsiteModalService } from './clearing-visit-website-modal.service';
import { ClearingVisitWebsiteBtnService } from '../../buttons/clearing-visit-website-btn/clearing-visit-website-btn.service';

@Component({
	selector: 'uc-clearing-visit-website-modal',
	imports: [CloseModalComponent, ExternalLinkSvgComponent],
	templateUrl: './clearing-visit-website-modal.component.html',
})
export class ClearingVisitWebsiteModalComponent implements OnDestroy {
	private _visitWebsiteModalService = inject(VisitWebsiteModalService);
	private _clearingVisitWebsiteBtnSrv = inject(ClearingVisitWebsiteBtnService);
	private _modalSrv = inject(ModalService);

	uniName = this._visitWebsiteModalService.uniName();
	externalLink = this._visitWebsiteModalService.externalLink();
	privacyPolicyUrl = this._visitWebsiteModalService.privacyPolicyUrl();

	ngOnDestroy(): void {
		this._visitWebsiteModalService.reset();
	}

	trackClick() {
		this._clearingVisitWebsiteBtnSrv.onClearingVisitWebsiteClick(
			this._visitWebsiteModalService.uniId(),
			this._visitWebsiteModalService.uniName(),
			this._visitWebsiteModalService.componentName(),
			this._visitWebsiteModalService.cleSearchOrderPosition(),
			this._visitWebsiteModalService.source(),
			this._visitWebsiteModalService.sourceType(),
			this._visitWebsiteModalService.trackingEnabled(),
		);

		this._modalSrv.close();
	}
}
