import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookWithinSphereSvgComponent } from '@uc/shared/svg';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { Router } from '@angular/router';
import { ProspectusModalService } from './prospectus-modal.service';
import {
	ProspectusGTMService,
	ConversionEventsService,
	abbreviateProduct,
} from '@uc/web/core';
import { CloseModalComponent, ModalService } from '@uc/shared/ui';
import { CustomCookieService } from '@uc/shared/authentication';
import { ProspectusExternalClickApiService } from '@uc/web/shared/data-access';

@Component({
	selector: 'uc-prospectus-modal',
	imports: [CloseModalComponent, CommonModule, BookWithinSphereSvgComponent],
	templateUrl: './prospectus-modal.component.html',
})
export class ProspectusModalComponent implements OnDestroy, OnDestroy {
	modalType$ = this._prospectusModalSrv.modalType$;
	privacyPolicyUrl$ = this._prospectusModalSrv.privacyPolicyUrl$;
	externalLinks$ = this._prospectusModalSrv.externalLinks$;
	isLoggedIn = this._customCookieSrv.get('user_id') ? true : false;

	product: Product | null = null;
	private _productSub = this._prospectusModalSrv.product$.subscribe((product) => {
		this.product = product;
	});

	private _componentName = '';
	private _componentNameSub = this._prospectusModalSrv.componentName$.subscribe(
		(componentName) => {
			this._componentName = componentName;
		},
	);

	private _uniId: string | number | undefined;
	private _uniIdSub = this._prospectusModalSrv.uniForProspectusOrder$.subscribe(
		(id) => {
			this._uniId = id;
		},
	);

	private _ugSearchOrderPosition!: number | null;
	private _ugSearchOrderPositionSub =
		this._prospectusModalSrv.ugSearchOrderPosition$.subscribe((order) => {
			this._ugSearchOrderPosition = order;
		});

	constructor(
		private _router: Router,
		private _prospectusModalSrv: ProspectusModalService,
		private _prospectusGTMSrv: ProspectusGTMService,
		private _customCookieSrv: CustomCookieService,
		private _conversionEventsSrv: ConversionEventsService,
		private _modalSrv: ModalService,
		private _prospectusExternalClickApiSrv: ProspectusExternalClickApiService,
	) {}

	ngOnDestroy(): void {
		this._prospectusModalSrv.setPrivacyPolicyUrl('');
		this._uniIdSub?.unsubscribe();
		this._productSub?.unsubscribe();
		this._componentNameSub?.unsubscribe();
		this._ugSearchOrderPositionSub?.unsubscribe();
	}

	getProspectusProductName(links: string[]) {
		if (links[0] && links[1]) {
			return '';
		} else if (links[1]) {
			return ProductEnum.Postgraduate;
		} else {
			return ProductEnum.Undergraduate;
		}
	}

	redirectToOrderPage() {
		this._prospectusModalSrv.setProduct(ProductEnum.Postgraduate);
		this._prospectusGTMSrv.getProspectusClick(
			this._componentName,
			this._ugSearchOrderPosition,
		);

		const queryParams = {
			uni: this._uniId,
			product: this.product && abbreviateProduct(this.product),
		};

		this._router.navigate(['/order'], {
			queryParams,
		});
	}

	trackExternalPageClick(index: number) {
		this._prospectusGTMSrv.getProspectusClick(
			this._componentName,
			this._ugSearchOrderPosition,
		);
		this._conversionEventsSrv.prospectusOrder(1);

		const product =
			index === 0 ? ProductEnum.Undergraduate : ProductEnum.Postgraduate;

		const uniId = this._prospectusModalSrv.turnUniIdIntoNumber(this._uniId);
		this._prospectusExternalClickApiSrv.prospectusExternalClicks(uniId, product);
		this._modalSrv.close();
	}
}
