<div
	aria-hidden="true"
	role="status"
	class="flex justify-between gap-12 py-12"
	[ngClass]="{ '!px-0': !filter }"
>
	@if (filter) {
		<div class="hidden w-76 animate-pulse lg:block">
			<div class="w-48 h-6 mb-6 bg-gray-200 rounded-full dark:bg-gray-500"></div>
			<div
				class="border bg-gray-400 rounded-full dark:bg-gray-500 max-w-[360px] mb-6"
			></div>
			<div
				class="w-full h-10 mb-6 bg-gray-200 rounded-lg dark:bg-gray-500 sm:mx-auto"
			></div>
			<div
				class="border bg-gray-400 rounded-full dark:bg-gray-500 max-w-[360px] mb-6"
			></div>
			<div class="h-4 mb-6 bg-gray-200 rounded-full dark:bg-gray-500"></div>
			<div
				class="border bg-gray-400 rounded-full dark:bg-gray-500 max-w-[330px] mb-6"
			></div>
			<div
				class="h-4 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[300px] mb-6"
			></div>
			<div
				class="border bg-gray-400 rounded-full dark:bg-gray-500 max-w-[360px] mb-6"
			></div>
			<div
				class="h-4 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[300px] mb-6"
			></div>
			<div
				class="border bg-gray-400 rounded-full dark:bg-gray-500 max-w-[360px] mb-6"
			></div>
			<div
				class="h-4 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[300px] mb-6"
			></div>
			<span class="sr-only">Loading...</span>
		</div>
	}
	@if (card) {
		<div class="w-full bg-white border rounded-lg sm:px-10 h-max animate-pulse">
			<div
				class="flex items-center justify-center flex-shrink-0 w-full h-40 overflow-hidden bg-gray-300 rounded-t-lg sm:hidden dark:bg-gray-400"
			>
				<uc-loading-image-svg />
			</div>
			<div class="px-8 py-8 -mt-20 sm:px-0 sm:mt-0">
				<div class="z-10 items-center sm:flex justify-items-center">
					<div
						class="flex items-center justify-center flex-shrink-0 w-20 h-20 mr-6 bg-gray-300 rounded-full dark:bg-gray-500"
					>
						<uc-loading-image-svg />
					</div>
					<div class="flex justify-between w-full mt-6 sm:mt-0">
						<div class="w-full">
							<div
								class="w-10/12 mb-4 bg-gray-200 rounded-full sm:w-1/2 h-7 dark:bg-gray-500"
							></div>
							<div
								class="w-9/12 h-4 bg-gray-200 rounded-lg sm:w-5/12 dark:bg-gray-500"
							></div>
						</div>
						<div
							class="hidden sm:block border bg-gray-400 h-10 w-full rounded-lg dark:bg-gray-500 max-w-[160px] mb-6 ml-auto"
						></div>
					</div>
				</div>
				<div class="my-6 bg-gray-200 rounded-lg dark:bg-gray-400 h-36"></div>
				<div class="h-10 border rounded-lg border-gra-200 dark:bg-gray-500"></div>
			</div>
		</div>
	}
</div>
