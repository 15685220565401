import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PromoSlotService } from '../services/promo-slot.service';
import { PromoSlotComponentName } from '@uc/web/shared/data-models';
import { Store } from '@ngrx/store';
import { getDefaultPromoSlotsData } from '@uc/web/shared/data-access';
import { getRandomPromoSlot } from '../utils/promo-slots.utils';

@Component({
	selector: 'uc-promo-slot-cta-card',
	imports: [NgOptimizedImage, RouterModule],
	templateUrl: './promo-slot-cta-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoSlotCtaCardComponent {
	promoSlots = this._store.selectSignal(getDefaultPromoSlotsData);
	promoSlot = computed(() => getRandomPromoSlot(this.promoSlots()));

	constructor(
		private _store: Store,
		private _promoSlotSrv: PromoSlotService,
	) {}

	onClick() {
		const promoSlot = this.promoSlot();
		const index = promoSlot?.index;

		if (promoSlot && index !== undefined) {
			this._promoSlotSrv.handlePromoSlotClick(
				promoSlot.link,
				PromoSlotComponentName.SIDEBAR,
				index,
				null,
			);
		}
	}
}
