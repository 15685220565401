import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	input,
	output,
} from '@angular/core';
import { MinusSvgComponent, PlusSvgComponent } from '@uc/shared/svg';
import { ToggleFaqDirective } from '@uc/utilities';
import { RadioSelect } from '@uc/web/shared/data-models';
import { InformationCircleSolidSvgComponent } from '@uc/shared/svg';
import { TooltipDirective } from '@uc/shared/ui';

@Component({
	selector: 'uc-radio-select',
	imports: [
		PlusSvgComponent,
		MinusSvgComponent,
		ToggleFaqDirective,
		InformationCircleSolidSvgComponent,
		TooltipDirective,
	],
	templateUrl: './radio-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioSelectComponent implements AfterViewInit {
	filterTitle = input.required<string>();
	options = input.required<RadioSelect[]>();
	type = input<'radio' | 'checkbox'>('radio');
	filterName = input<string>();
	state = input<'open' | 'closed'>();
	tooltips = input<{ filterLabel: string; tooltip: string }[]>();

	selected = output<RadioSelect>();
	filterState = output<string>();

	constructor(private el: ElementRef) {}

	ngAfterViewInit(): void {
		if (this.state() === 'open') {
			const svgs = this.el.nativeElement.getElementsByTagName('svg');
			this.el.nativeElement.querySelector('.sr-only').classList.remove('sr-only');
			svgs[0].classList.add('hidden');
			svgs[1].classList.remove('hidden');
		}
	}

	onSelect(option: RadioSelect) {
		this.options().forEach((option) => (option.checked = false));
		this.selected.emit(option);
		option.checked = true;
	}

	onFilterState() {
		const filterName = this.filterName();
		if (filterName) {
			this.filterState.emit(filterName);
		}
	}
}
