import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BookEvent, BookEventGTMService } from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { CommonModule } from '@angular/common';
import { CustomCookieService } from '@uc/shared/authentication';
import { BookEventModalService } from '../../modals/book-event-modal/book-event-modal.service';
import { ModalService } from '@uc/shared/ui';
import { BookEventModalComponent } from '../../modals/book-event-modal/book-event-modal.component';

@Component({
	selector: 'uc-book-event-btn',
	templateUrl: './book-event-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class BookEventBtnComponent {
	uniId = input.required<number>();
	product = input.required<Product>();
	externalUrl = input.required<string>();
	componentName = input.required<string>();
	uniName = input.required<string>();
	ugSearchOrderPosition = input.required<number | null>();
	privacyPolicyUrl = input.required<string | null>();
	eventType = input<string | null>();
	date = input<string | null>();
	customStyles = input('w-auto');
	buttonText = input('Book Event');
	eventId = input<number | null>(null);

	userId = this._customCookieSrv.get('user_id');

	constructor(
		private _modalSrv: ModalService,
		private _customCookieSrv: CustomCookieService,
		private _bookEventModalSrv: BookEventModalService,
		private _bookEventGTMSrv: BookEventGTMService,
	) {}

	onBookEventClick() {
		const args = this._generateBookEventGTMArgs();
		this._bookEventGTMSrv.bookEventClick(args);
	}

	openModal() {
		const args = this._generateBookEventGTMArgs();

		this._bookEventModalSrv.externalLink.set(this.externalUrl());
		this._bookEventModalSrv.eventId.set(this.eventId());
		this._bookEventModalSrv.product.set(this.product());
		this._bookEventModalSrv.bookEventGTMArgs.set(args);
		this._bookEventModalSrv.privacyPolicyUrl.set(this.privacyPolicyUrl());

		this._modalSrv.setContent(BookEventModalComponent);
		this._modalSrv.show();
	}

	private _generateBookEventGTMArgs(): BookEvent {
		return {
			uniId: this.uniId(),
			uniName: this.uniName(),
			componentName: this.componentName(),
			eventType: this.eventType(),
			date: this.date(),
			isUser: this.userId ? true : false,
			ugSearchOrderPosition: this.ugSearchOrderPosition(),
		};
	}
}
