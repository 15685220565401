import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Product, UppercaseProduct } from '@uc/web/shared/data-models';
import { BookEventBtnComponent } from '../buttons/book-event-btn/book-event-btn.component';
import {
	AbbreviateButtonTextPipe,
	AbbreviateProductPipe,
	EntitiesPipe,
} from '@uc/utilities';

@Component({
	selector: 'uc-event-card',
	templateUrl: './event-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		EntitiesPipe,
		BookEventBtnComponent,
		CommonModule,
		RouterModule,
		NgOptimizedImage,
		AbbreviateProductPipe,
		AbbreviateButtonTextPipe,
	],
})
export class EventCardComponent {
	uniName = input.required<string>();
	uniLogo = input.required<string | null>();
	slug = input.required<string>();
	uniId = input.required<number>();
	id = input.required<number>();
	date = input.required<string>();
	description = input.required<string>();
	subtitle = input.required<string | null>();
	externalUrl = input.required<string>();
	ugSearchOrderPosition = input.required<number | null>();
	privacyPolicyUrl = input.required<string | null>();
	componentName = input<string>('');
	eventType = input<string>('');
	product = input.required({
		transform: (product: UppercaseProduct | Product) =>
			product.toLowerCase() as Product,
	});
}
