import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LeadsUniDetailsImageComponent } from './leads-uni-details-image/leads-uni-details-image.component';

@Component({
	selector: 'uc-leads-university-details-v1',
	templateUrl: './leads-university-details-v1.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, LeadsUniDetailsImageComponent],
})
export class LeadsUniversityDetailsV1Component {
	coverImage = input.required<string>();
	uniName = input.required<string>();
	uniLogo = input.required<string>();
	recommendedPercentage = input.required<number>();
	courseCount = input.required<number>();
	isClearingCourses = input<boolean>(false);
	displayBottomBorder = input<boolean>(true);
}
