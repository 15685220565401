import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { PRODUCT_KEYS, PRODUCT_OPTIONS } from './select-product-form.properties';
import { NgClass } from '@angular/common';
import { Product } from '@uc/web/shared/data-models';
import { FormErrorComponent } from '@uc/shared/ui';
import { ArrowRightSvgComponent } from '@uc/shared/svg';
import { Router } from '@angular/router';

@Component({
	selector: 'uc-select-product-form',
	imports: [NgClass, FormErrorComponent, ArrowRightSvgComponent],
	templateUrl: './select-product-form.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProductFormComponent {
	error = input.required<string>();
	redirectTo = input.required<string>();
	buttonStyles = input.required<string>();
	selectedProduct = signal<Product | null>(null);
	isError = signal(false);

	readonly productOptions = PRODUCT_OPTIONS;
	private readonly _products = PRODUCT_KEYS;

	private _router = inject(Router);

	setProduct() {
		if (!this.selectedProduct()) {
			this.isError.set(true);
			return;
		}

		this.isError.set(false);
		this._router.navigate([`/${this.redirectTo()}`], {
			queryParams: {
				product:
					this._products[this.selectedProduct() as keyof typeof this._products],
			},
			queryParamsHandling: 'merge',
		});
	}

	selectProduct(product: Product) {
		this.selectedProduct.set(product);
	}

	isProductSelected(product: Product) {
		return this.selectedProduct() === product;
	}
}
