import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingAnimationService } from '@uc/shared/ui';
import { CdnPathPipe, OrdinalPipe } from '@uc/utilities';
import { UniversityProfile } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-leads-university-details',
	templateUrl: './leads-university-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [OrdinalPipe, CdnPathPipe, CommonModule, NgOptimizedImage],
})
export class LeadsUniversityDetailsComponent {
	@Input() uni!: Partial<UniversityProfile> | null;
	@Input() loading = false;
	@Input() noSelectInfo!: string;
	@Input() hidePolicy = false;
	@Input() policyText = '';
	@Input() displayGoogleMap!: boolean;

	loadAnimation = this.loadSrv.createUniCard();

	constructor(private loadSrv: LoadingAnimationService) {}
}
