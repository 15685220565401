import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	input,
	OnChanges,
	OnInit,
	output,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MinusSvgComponent, PlusSvgComponent } from '@uc/shared/svg';
import { FormErrorComponent } from '@uc/shared/ui';
import { ToggleFaqDirective } from '@uc/utilities';
import { MAX_UCAS_TARIFF } from '@uc/web/shared/data-access';

@Component({
	selector: 'uc-ucas-input',
	imports: [
		PlusSvgComponent,
		MinusSvgComponent,
		FormErrorComponent,
		ReactiveFormsModule,
		FormsModule,
		ToggleFaqDirective,
	],
	templateUrl: './ucas-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UcasInputComponent implements OnInit, AfterViewInit, OnChanges {
	minValue = input.required<number | undefined>();
	maxValue = input.required<number | undefined>();
	filterTitle = input<string>('Your UCAS Tariff');
	filterName = input<string>();
	state = input<'open' | 'closed'>();

	setUcasPoints = output<string>();
	filterState = output<string>();

	readonly maxScore = MAX_UCAS_TARIFF;

	ucasForm!: FormGroup;
	formSubmitted = false;
	customError = '';

	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		this.ucasForm = new FormGroup({
			min: new FormControl(null, [
				Validators.min(1),
				Validators.max(this.maxScore - 1),
			]),
			max: new FormControl(null, [
				Validators.min(2),
				Validators.max(this.maxScore),
			]),
		});
		this._patchFormValues();
	}

	ngAfterViewInit(): void {
		if (this.state() === 'open') {
			const svgs = this.el.nativeElement.getElementsByTagName('svg');
			this.el.nativeElement.querySelector('.sr-only').classList.remove('sr-only');
			svgs[0].classList.add('hidden');
			svgs[1].classList.remove('hidden');
		}
	}

	ngOnChanges(): void {
		if (this.ucasForm) this._patchFormValues();
	}

	get min() {
		return this.ucasForm.get('min') as FormControl;
	}

	get max() {
		return this.ucasForm.get('max') as FormControl;
	}

	onSubmit(): void {
		this.formSubmitted = true;
		this.customError = '';

		const isFormValid = this._validateUcasInput();
		if (isFormValid) {
			const { min, max } = this.ucasForm.value;
			this.setUcasPoints.emit(`${min}_${max}`);
		}
	}

	onFilterState() {
		const filterName = this.filterName();
		if (filterName) {
			this.filterState.emit(filterName);
		}
	}

	private _patchFormValues() {
		if (this.minValue() && this.maxValue()) {
			this.ucasForm.setValue({
				min: this.minValue(),
				max: this.maxValue(),
			});
		} else {
			this.ucasForm.reset();
		}
	}

	private _validateUcasInput(): boolean {
		const { min, max } = this.ucasForm.value;

		// don't emit filter if nothing changed
		if (min === this.minValue() && max === this.maxValue()) {
			this.customError = 'no-changes';
			return false;
		}

		// user has only entered valid MAX value
		if (!min && max && max > 1 && max <= this.maxScore) {
			this.ucasForm.patchValue({ min: 1 });
			return true;

			// user has only entered valid MIN value
		} else if (min && !max && min >= 1 && min <= this.maxScore) {
			this.ucasForm.patchValue({ max: this.maxScore });
			return true;

			// user has not entered any value
		} else if (!min && !max) {
			this.customError = 'required';
			return false;

			// user has entered invalid range
		} else if (min > max) {
			this.customError = 'range';
			return false;

			// user has entered invalid value
		} else if (
			this.min.hasError('min') ||
			this.min.hasError('max') ||
			this.max.hasError('max') ||
			this.max.hasError('min')
		) {
			this.customError = 'invalid';
			return false;
		}

		return true;
	}
}
