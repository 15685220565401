import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-engage-card-cta-img',
	templateUrl: './engage-card-cta-img.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, CommonModule],
})
export class EngageCardCtaImgComponent {
	@Input() backgroundImageClass!: string;
	@Input() backgroundImage!: string;
	@Input() border!: boolean;
}
