import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	input,
	model,
	OnChanges,
	output,
	viewChildren,
} from '@angular/core';
import { CourseSuggestion } from '../../models/course-search-autosuggest.models';
import { CommonModule } from '@angular/common';
import { ArrowRightV2SvgComponent } from '@uc/shared/svg';
import { DegreeLevelOption } from '@uc/web/shared/data-models';
@Component({
	selector: 'uc-course-suggestions',
	templateUrl: './course-suggestions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, ArrowRightV2SvgComponent],
})
export class CourseSuggestionsComponent implements OnChanges {
	focusedIndex = model.required<number>();
	parentInput = input.required<HTMLInputElement>();
	suggestions = input.required<CourseSuggestion[]>();
	selectedDegreeLevel = input.required<DegreeLevelOption>();
	maxSuggestions = input.required<number>();

	selectSuggestion = output<string>();
	resetFocusedIndex = output<boolean>();
	scrolled = output<void>();

	autosuggestItems = viewChildren<ElementRef<HTMLLIElement>>('autosuggestItem');

	ngOnChanges() {
		if (this.focusedIndex() === 0) {
			this.setFocusOnItem(this.focusedIndex());
		}
	}

	onScroll() {
		this.scrolled.emit();
	}

	// Handles keyboard events for the autosuggest dropdown.
	handleKeyboardEvent(event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowDown':
				event.preventDefault();
				this.focusNextItem();
				break;
			case 'ArrowUp':
				event.preventDefault();
				this.focusPreviousItem();
				break;
			case 'Enter':
				if (this.focusedIndex() >= 0 && this.focusedIndex() < 10) {
					this.setItem(this.suggestions()[this.focusedIndex()].link);
				}
				break;
		}
	}

	// Moves focus to next in list.
	focusNextItem() {
		this.focusedIndex.set(Math.min(this.focusedIndex() + 1, this.maxSuggestions()));

		if (
			this.focusedIndex() === (this.suggestions().length || this.maxSuggestions())
		) {
			this.focusedIndex.set(0);
			this.setFocusOnItem(this.focusedIndex());
		}
		this.setFocusOnItem(this.focusedIndex());
		this.scrollOptionIntoView();
	}

	// Moves focus to previous in List or to the parent component.
	focusPreviousItem() {
		this.focusedIndex.set(Math.max(this.focusedIndex() - 1, -1));
		if (this.focusedIndex() === -1) {
			this.parentInput().focus();
			this.resetFocusedIndex.emit(true);
		} else {
			this.setFocusOnItem(this.focusedIndex());
			this.scrollOptionIntoView();
		}
	}

	// Sets focus on the item in the list.
	setFocusOnItem(index: number) {
		const itemsArray = this.autosuggestItems();

		if (itemsArray[index + 1]) {
			const listItem = itemsArray[index + 1].nativeElement;
			listItem.focus();
		}
	}

	// Sets the suggestion chosen by the user and emits it to the parent input
	// or searches for the term if the user is on mobile view.
	setItem(suggested: string) {
		this.selectSuggestion.emit(suggested);
		this.resetFocusedIndex.emit(true);
	}

	//when user navigates with keyboard, the options in dropdown become visible if the dropdown
	// overflows the page.
	scrollOptionIntoView(): void {
		const options = this.autosuggestItems().filter(
			(ele, i) => this.focusedIndex() === i,
		);
		if (options[0]) {
			options[0].nativeElement?.scrollIntoView({
				block: 'nearest',
				behavior: 'smooth',
			});
		}
	}
}
