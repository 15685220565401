import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EngageCardCtaImgComponent } from '../engage-card-cta/components/engage-card-cta-img/engage-card-cta-img.component';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-subject-rankings-cta',
	imports: [RouterModule, EngageCardCtaImgComponent, NgOptimizedImage, CdnPathPipe],
	templateUrl: './subject-rankings-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubjectRankingsCtaComponent {
	rankingsSlug = input.required<string>();
	category = input.required({
		transform: (value: string) => value.toLowerCase(),
	});

	slug = computed(() => {
		return this.rankingsSlug()
			? `/rankings/subjects/${this.rankingsSlug()}`
			: '/rankings/subjects/';
	});

	cardTitle = computed(() => {
		return this.rankingsSlug()
			? 'Discover top unis for ' + this.category()
			: 'Discover top universities';
	});

	description = computed(() => {
		return this.rankingsSlug()
			? `Discover the top universities for ${this.category()} with our subject rankings.`
			: 'Our university rankings focus on the areas we know matter to you - check them out!';
	});

	buttonText = computed(() => {
		return this.rankingsSlug() ? `View ${this.category()} rankings` : 'View rankings';
	});
}
