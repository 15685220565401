import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CallUniBtnApiService } from './call-uni-btn-api.service';
import { CallUniComponentName, SourceTrackingTypes } from '@uc/web/shared/data-models';
import { CallUniversityGTMService, ConversionEventsService } from '@uc/web/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-call-uni-btn',
	templateUrl: './call-uni-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class CallUniBtnComponent {
	phoneNumber = input.required<string>();
	uniId = input.required<number>();
	uniName = input.required<string>();
	source = input.required<string>();
	sourceType = input.required<SourceTrackingTypes>();
	componentName = input.required<CallUniComponentName>();
	cleSearchOrderPosition = input.required<number>();
	buttonText = input<string>('Call');
	mobileButtonText = input<string>('');
	customStyles = input<string>('');

	showNumber = false;

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _callUniBtnApiSrv: CallUniBtnApiService,
		private _callUniGTMSrv: CallUniversityGTMService,
	) {}

	onCallUni() {
		this.showNumber = true;
		this._conversionEventsSrv.callUni();

		this._callUniBtnApiSrv.trackClick(this.uniId(), this.source(), this.sourceType());
		this._callUniGTMSrv.callUniClick(
			this.uniId(),
			this.uniName(),
			this.componentName(),
			this.cleSearchOrderPosition(),
		);
	}
}
