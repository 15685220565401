import { Location, NgOptimizedImage } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	input,
	OnInit,
} from '@angular/core';
import {
	CourseGTMService,
	ImpressionDirective,
	ClientValidatorService,
	UniversityGTMService,
	CourseSearchPathService,
} from '@uc/web/core';
import {
	ClientFeatureOptions,
	ClientFeatures,
	FeaturedClearing,
	FeaturedPostgraduate,
	FeaturedUndergraduate,
	Product,
	ProductEnum,
} from '@uc/web/shared/data-models';
import { BookmarkComponent } from '../bookmark/bookmark.component';
import { CdnPathPipe } from '@uc/utilities';
import { RouterModule } from '@angular/router';
import { CtaUniversityBtnsComponent } from '../cta-university-btns/cta-university-btns.component';
import { SecondaryBlueBtnComponent } from '../buttons/secondary-blue-btn/secondary-blue-btn.component';
import { VisitWebsiteBtnComponent } from '../buttons/visit-website-btn/visit-website-btn.component';
import { WriteReviewBtnComponent } from '../buttons/write-review-btn/write-review-btn.component';

@Component({
	selector: 'uc-university-card',
	templateUrl: './university-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		VisitWebsiteBtnComponent,
		SecondaryBlueBtnComponent,
		CtaUniversityBtnsComponent,
		BookmarkComponent,
		ImpressionDirective,
		NgOptimizedImage,
		RouterModule,
		WriteReviewBtnComponent,
	],
})
export class UniversityCardComponent implements OnInit {
	uniName = input.required<string>();
	uniId = input.required<number>();
	uniSlug = input.required<string>();
	uniCover = input.required<string>();
	uniLogo = input.required<string>();
	clientFeatures = input.required<ClientFeatureOptions[]>();
	recommended = input.required<number | undefined>();
	saved = input.required<boolean>();
	displaySeeCoursesBtn = input.required<boolean>();
	ugSearchOrderPosition = input.required<number | null>();
	product = input.required<Product | null>();
	source = input<'universities'>();
	externalUrl = input<string>('');
	searchTerm = input<string>('');
	externalUgProspectusUrl = input<string | null>(null);
	externalPgProspectusUrl = input<string | null>(null);
	privacyPolicyUrl = input<string>('');

	buttonText = computed(() =>
		this.source() === 'universities'
			? `See ${this.product()} Courses`
			: 'See More Courses',
	);

	queryParams = computed(() => ({
		...(this.searchTerm() &&
			this.source() !== 'universities' && { search: this.searchTerm() }),
		...(this.uniSlug() && { uni: this.uniSlug() }),
	}));

	isFeatured = computed(() => {
		const product = this.product();
		if (product) {
			return this.cvSrv.checkFeature(
				this.clientFeatures(),
				ClientFeatures[this.getFeatureKey(product)],
			);
		}
		return this.cvSrv.checkFeature(this.clientFeatures(), this.paidFeatures);
	});

	internalUniUrl!: string;

	readonly paidFeatures = [ClientFeatures.Undergraduate, ClientFeatures.Postgraduate];
	readonly clientFeatureOptions = ClientFeatures;

	constructor(
		private _location: Location,
		private _uniGTMSrv: UniversityGTMService,
		private _courseGTMSrv: CourseGTMService,
		private _courseSearchPathSrv: CourseSearchPathService,
		public cvSrv: ClientValidatorService,
	) {}

	ngOnInit(): void {
		this.internalUniUrl = this.getUniUrl();
	}

	getUniUrl(): string {
		if (this.isPostgraduateOnly()) {
			return `/universities/${this.uniSlug()}/postgraduate`;
		}
		return `/universities/${this.uniSlug()}/${this.product()}`;
	}

	isPostgraduateOnly(): boolean {
		return (
			!this.clientFeatures()?.includes('UNDERGRADUATE_PROFILE') &&
			this.clientFeatures()?.includes('POSTGRADUATE_PROFILE')
		);
	}

	getFeatureKey(str: string) {
		return (str.charAt(0).toUpperCase() +
			str.slice(1)) as keyof typeof ClientFeatures;
	}

	// GTM event
	onUniProfileClick() {
		this._uniGTMSrv.uniProfileClick(
			this.uniId(),
			this.uniName(),
			this.ugSearchOrderPosition(),
			null,
			this.product() ?? 'undergraduate',
		);
	}

	onUniImpression(impression: any) {
		if (impression) {
			this._uniGTMSrv.uniImpression(
				this.uniId(),
				this.uniName(),
				this.ugSearchOrderPosition(),
				null,
				this.product() ?? 'undergraduate',
			);
		}
	}

	/*
	   GTM Event when the See all courses button is clicked.
	   Should work when either a search term has been entered or no search term
	   has been entered.
	*/
	onViewMoreCourses() {
		this._courseSearchPathSrv.path.set(this._location.path());
		this._courseGTMSrv.viewMore(this.uniId(), this.uniName(), this.searchTerm());
	}
}
