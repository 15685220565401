import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegisterInterestGTMService, ConversionEventsService } from '@uc/web/core';
import {
	RegisterInterestComponentName,
	SourceTrackingTypes,
} from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-register-interest-btn',
	templateUrl: './register-interest-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class RegisterInterestBtnComponent {
	uniId = input.required<number>();
	sourceType = input.required<SourceTrackingTypes>();
	cleSearchOrderPosition = input.required<number | null>();
	componentName = input.required<RegisterInterestComponentName>();
	customStyles = input('w-full');
	buttonText = input('Register Interest');
	mobileButtonText = input('Register Interest');

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _registerInterestGTMSrv: RegisterInterestGTMService,
	) {}

	onRegisterInterestClick(): void {
		this._conversionEventsSrv.clickRegisterInterest();
		this._registerInterestGTMSrv.clickRegisterInterest(
			this.componentName(),
			this.cleSearchOrderPosition(),
		);
	}
}
