import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingSpinnerSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-loading-state-btn',
	imports: [LoadingSpinnerSvgComponent],
	templateUrl: './loading-state-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingStateBtnComponent {
	@Input() buttonText = 'Loading...';
}
