import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdnPathPipe, EntitiesPipe } from '@uc/utilities';

@Component({
	selector: 'uc-static-card',
	templateUrl: './static-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, CommonModule, NgOptimizedImage, RouterModule, EntitiesPipe],
})
export class StaticCardComponent {
	title = input.required<string>();
	desc = input.required<string>();
	image = input.required<string>();
	link = input.required<string>();
	external = input.required<boolean>();
	uni = input<{ uni: string }>();
	customHeight = input('lg:h-44');

	cardClick = output<boolean>();
}
