import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousPageBtnService } from './previous-page-btn.service';
import { map } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-previous-page-btn',
	imports: [CommonModule, RouterModule],
	templateUrl: './previous-page-btn.component.html',
})
export class PreviousPageBtnComponent {
	previousPageName = '';
	showDownloadCta = false;

	prevPath$ = this._prevPageBtnSrv.previousPath$.pipe(
		map((path: string) => {
			this.showDownloadCta = path === this._prevPageBtnSrv.appLink;
			this.previousPageName = this._prevPageBtnSrv.getPageTitle(path);
			return this._prevPageBtnSrv.getQueryParams(path);
		}),
	);

	constructor(private _prevPageBtnSrv: PreviousPageBtnService) {}
}
