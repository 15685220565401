<button
	(click)="onClick()"
	class="block text-left cursor-pointer"
	data-test-id="promo-slot-card"
	data-cy="promo-slot-card"
>
	<img
		[attr.src]="card().image"
		[attr.alt]="'Image of ' + card().title"
		class="object-cover rounded-2xl lg:w-full"
		[ngClass]="customHeight()"
	/>

	<h3 class="mt-4 text-lg font-bold text-gray-900">
		{{ card().title }}
	</h3>

	<p class="mt-2 text-base text-gray-500">
		{{ card().description }}
	</p>
</button>
