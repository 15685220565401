import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-primary-blue-btn',
	templateUrl: './primary-blue-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class PrimaryBlueBtnComponent {
	@Input() link = '';
	@Input() buttonText = '';
	@Input() customStyles = '';
}
