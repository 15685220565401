import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { HeadingComponent } from '@uc/shared/ui';
import {
	ClientFeatureOptions,
	Product,
	UniversityEvent,
} from '@uc/web/shared/data-models';
import { EventCardComponent } from '../event-card/event-card.component';
import { NoOpenDaysComponent } from '../no-open-days/no-open-days.component';
@Component({
	selector: 'uc-university-events',
	templateUrl: './university-events.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [HeadingComponent, EventCardComponent, NoOpenDaysComponent],
})
export class UniversityEventsComponent {
	paidFeatures = input.required<ClientFeatureOptions[]>();
	rating = input.required<number | undefined>();
	product = input.required<Product>();
	events = input.required<UniversityEvent[]>();
	uniName = input.required<string>();
	uniSlug = input.required<string>();
	externalEventsUrl = input.required<string>();
	uniId = input.required<number>();
	ugSearchOrderPosition = input.required<number | null>();
	privacyPolicyUrl = input.required<string | null>();
	uniLogo = input<string>('');
	componentName = input<string>('Event Ticket');
}
