<div class="flex justify-between mb-6 sm:justify-start sm:gap-6">
	<!-- twitter -->
	<a
		[attr.href]="shareLink.twitter"
		aria-label="Share via Twitter"
		target="_blank"
		rel="noopener"
		title="Share by Twitter"
		class="cursor-pointer hover:opacity-80"
		(click)="onShareArticle(title, 'twitter')"
		data-cy="share-article-twitter"
	>
		<uc-twitter-svg />
	</a>

	<!-- facebook -->
	<a
		[attr.href]="shareLink.facebook"
		aria-label="Share via Facebook"
		target="_blank"
		rel="noopener"
		title="Share by Facebook"
		class="cursor-pointer hover:opacity-80"
		(click)="onShareArticle(title, 'facebook')"
		data-cy="share-article-facebook"
	>
		<uc-facebook-svg />
	</a>

	<!-- linkedin -->
	<a
		[attr.href]="shareLink.linkedin"
		aria-label="Share via LinkedIn"
		target="_blank"
		rel="noopener"
		title="Share by LinkedIn"
		class="cursor-pointer hover:opacity-80"
		(click)="onShareArticle(title, 'linkedin')"
		data-cy="share-article-linkedin"
	>
		<uc-linkedin-round-svg />
	</a>

	<!-- pinterest -->
	<a
		[attr.href]="shareLink.pinterest"
		aria-label="Share via Pinterest"
		target="_blank"
		rel="noopener"
		title="Share by Pinterest"
		class="cursor-pointer hover:opacity-80"
		(click)="onShareArticle(title, 'pinterest')"
		data-cy="share-article-pinterest"
	>
		<uc-pinterest-svg />
	</a>

	<!-- email -->
	<a
		[attr.href]="shareLink.email"
		aria-label="Share via Email"
		target="_blank"
		title="Share by Email"
		class="cursor-pointer hover:opacity-80"
		(click)="onShareArticle(title, 'email')"
		data-cy="share-article-email"
	>
		<uc-email-round-svg />
	</a>

	<!-- general link -->
	<button
		(click)="copyLink()"
		aria-label="Copy link to clipboard"
		title="Copy link to clipboard"
		class="cursor-pointer hover:opacity-80"
		(click)="onShareArticle(title, 'copy')"
		data-cy="share-article-copy"
	>
		<uc-link-svg />
	</button>
</div>
