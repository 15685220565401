import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ConversionEventsService } from '@uc/web/core';
import { CommonModule } from '@angular/common';
import { SourceTrackingTypes, Product } from '@uc/web/shared/data-models';
import { VisitWebsiteBtnService } from './visit-website-btn.service';

@Component({
	selector: 'uc-visit-website-btn',
	templateUrl: './visit-website-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class VisitWebsiteBtnComponent {
	uniId = input.required<number | null>();
	uniName = input.required<string>();
	source = input.required<string>(); // uni name or course name
	sourceType = input.required<SourceTrackingTypes>();
	product = input.required<Product | null>();
	externalUrl = input.required<string | undefined>();
	ugSearchOrderPosition = input.required<number | null>();
	componentName = input.required<string>();
	buttonText = input<string>('Visit Website');
	customStyles = input<string>('w-auto');
	trackingEnabled = input<boolean>(true);

	visitWebsiteBtnClick = output<void>();

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _visitWebsiteBtnSrv: VisitWebsiteBtnService,
	) {}

	// GTM Event
	onVisitWebsiteClick() {
		this._visitWebsiteBtnSrv.onVisitWebsiteClick(
			this.uniId(),
			this.uniName(),
			this.componentName(),
			this.ugSearchOrderPosition(),
			this.source(),
			this.product(),
			this.sourceType(),
			this.trackingEnabled(),
		);
	}

	// Google Ads Conversion
	onVisitWebsiteConversion() {
		this._conversionEventsSrv.visitWebsite();
	}
}
